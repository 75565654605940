<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
      <!-- Content -->
      <v-col class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
          <v-col align="center" class="col-sm-6 ma-0 pa-0">
            <v-card class="pa-10">
              <div v-if="fullStoryMessage">
                <p>Please register with an account to view full veteran stories.</p>
              </div>
              <div v-if="createStoryMessage">
                <p>Please register to create a veteran's story.</p>
              </div>
              <v-form 
                v-if="!isEmailSent"
                @submit.prevent="submit"
                ref="form"
                v-model="valid"
              >
                <h1>Register</h1>
                <v-text-field v-model="firstName" label="First Name" required></v-text-field>
                <v-text-field v-model="lastName" label="Last Name" required></v-text-field>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  ref="emailField"
                  label="Email"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password1"
                  :rules="passwordRules"
                  :type="'password'"
                  label="Password"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password2"
                  :rules="passwordRules"
                  :type="'password'"
                  label="Confirm Password"
                  required
                ></v-text-field>
                <v-checkbox v-model="isVeteran" label="I am a veteran or active duty servicemember." class="mt-2"></v-checkbox>
                <v-checkbox v-model="isAcceptedTerms" :rules="checkboxRules" class="mt-2">
                  <template v-slot:label>
                    <div>
                      I agree to abide by the Honor Tag 
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="https://www.honor-tag.com/#/code"
                            @click.stop
                            v-on="on"
                          >
                            code of conduct</a>
                        </template>
                        Opens in new window
                      </v-tooltip>
                      while using the website.
                    </div>
                  </template>
                </v-checkbox>
                <v-btn
                  type="submit"
                  class="blue darken-3 mt-3 white--text"
                  :loading="loading"
                >
                  Submit
                </v-btn>
                <div class="error--text mt-3">{{ error }}</div>
              </v-form>
              <div v-else>
                <span>We sent you a verification email. Please click the link inside that email to verify your account.</span>
              </div>
            </v-card>
          </v-col>
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Register',

    data () {
      return {
        checkboxRules: [
          v => !!v || 'Required field.'
        ],
        createStoryMessage: this.$route.params.createStoryMessage,
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid.'
        ],
        error: null,
        fullStoryMessage: this.$route.params.fullStoryMessage,
        firstName: '',
        isAcceptedTerms: false,
        isEmailSent: false,
        isVeteran: false,
        lastName: '',
        loading: false,
        password1: '',
        password2: '',
        valid: false
      }
    },

    computed: {
      passwordRules() {
        const { password1, password2 } = this
        return [
          v => !!v || 'Password is required.',
          v => v.length >= 8 || 'Passwords must contain at least 8 characters.',
          password1 == password2 || 'Passwords must match.'
        ]
      }
    },

    methods: {
      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          const { firstName, lastName, email, password1, password2, isVeteran } = this
          this.$store.dispatch('register', { firstName, lastName, email, password1, password2, isVeteran })
          .then(() => {
            this.loading = false
            this.isEmailSent = true
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      }
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>